/***    发票    ***/
const invoiceHistory = () => import(/* webpackChunkName: "invoice" */ "@/views/invoice/InvoiceHistory")
const commitInvoice = () => import(/* webpackChunkName: "invoice" */ "@/views/invoice/CommitInvoice")
const invoiceDetail = () => import(/* webpackChunkName: "invoice" */ "@/views/invoice/InvoiceDetail")
const bindingCollection = () => import(/* webpackChunkName: "invoice" */ "@/views/invoice/bindingCollection")

export default [
    {
        path: '/invoiceHistory',
        name: 'invoiceHistory',
        meta: {
            index: 3,
            title: "开票历史",
            useApp: true,
            needLogin: true
        },
        component: invoiceHistory,
    },
    {
        path: '/commitInvoice',
        name: 'commitInvoice',
        meta: {
            index: 4,
            title: "开票"
        },
        component: commitInvoice
    }, {
        path: '/invoiceDetail',
        name: 'invoiceDetail',
        meta: {
            index: 4,
            title: "发票详情"
        },
        component: invoiceDetail
    },{
        path: '/bindingCollection',
        name: 'bindingCollection',
        meta: {
            index: 5,
            title: "绑定收款"
        },
        component: bindingCollection
    }
]