const Inbox = () => import(/* webpackChunkName: "mailBox" */ "@/views/mailBox/inbox")
const EmailDetails = () => import(/* webpackChunkName: "mailBox" */ "@/views/mailBox/emailDetails")
export default[
    {
        path:'/inbox',
        name:'Inbox',
        meta: {
            index: 3,
            title: "收件箱"
        },
        component:Inbox
    },{
        path:'/inbox/emailDetails/:id',
        name:'EmailDetails',
        meta: {
            index: 4,
            title: "邮件详情"
        },
        component:EmailDetails
    }
]