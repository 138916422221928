import request from '@/api/http'

const equipment = {
    equipmentInventoryFinish(params,callback){
        return request.get(process.env.BASE_URL+`/equipment/inventoryDetail/finsh`,params,callback)
    },
    getInventoryPageListDetail(params,callback){
        return request.get(process.env.BASE_URL+`/equipment/inventoryDetail/pageList`,params,callback)
    },
    getInventoryPageList(params,callback){
        return request.get(process.env.BASE_URL+`/equipment/inventory/pageList`,params,callback)
    },
    getInventoryInfo(params,callback){
        return request.get(process.env.BASE_URL+`/equipment/inventoryDetail/isFinsh/`+params.id,{},callback)
    },
    getEquipmentCheckList(params,callback){
        return request.post(process.env.BASE_URL+`/equipment/getEquipmentCheckList`, params,callback)
    },
    bindingEquipment(params,callback){
        return request.post(process.env.BASE_URL+`/equipment/bindingEquipment`, params,callback)
    },
    getEquipment(params,callback){
        return request.post(process.env.BASE_URL+`/equipment/getEquipment`, params,callback)
    },
    getEquipmentList(params,callback){
        return request.post(process.env.BASE_URL+`/equipment/getEquipmentList`, params,callback)
    },
    addEquipment(params,callback){
        return request.post(process.env.BASE_URL+`/equipment/addEquipment`, params,callback)
    },
    updateEquipment(params,callback){
        params.method='PUT'
        return request.post(process.env.BASE_URL+`/equipment/updateEquipment`, params,callback)
    },
    addClock(params,callback){
        return request.post(process.env.BASE_URL+`/employee/tmp/addTmpEmployeeClock`, params,callback)
    },
    addRepair(params,callback){
        return request.post(process.env.BASE_URL+`/equipment/repair`, params,callback)
    },
    getRepairList(params,callback){
        return request.get(process.env.BASE_URL+`/equipment/repair/pageList`, params,callback)
    },
    getRepairDetailById(params,callback){
        return request.get(process.env.BASE_URL+`/equipment/repair/`+params.id, {},callback)
    },
    //处理报修
    repairDevice(params,callback){
        return request.post(process.env.BASE_URL+'/equipment/repair/handle', params,callback)
    },
    //维修反馈
    repairFeedback(params,callback){
        return request.post(process.env.BASE_URL+'/equipment/repair/feedback', params,callback)
    },
    //撤回报修
    cancelRepair(params,callback){
        return request.post(process.env.BASE_URL+'/equipment/repair/cancel', params,callback)
    },

}


export default equipment
