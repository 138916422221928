export default {
    state: {
        list: [{
            "id": null,
            "roleName": null,
            "remark": null,
            "createTime": null,
            "isActive": null,
            "roleList": null
        }]
    },
    mutations: {
        updateRoleInfo(state, roleInfo) {
            localStorage.setItem("roleInfo", JSON.stringify(roleInfo))
            state.list = roleInfo
        },
        init(state) {
            let roleInfo=JSON.parse(localStorage.getItem("roleInfo"))
            if(roleInfo==null){
                return
            }
            state.list = roleInfo
        },
    },

    getters:{
        getRoleInfo:(state) => (roleName) => {
            let roleInfo = state.list
            if (roleInfo == null) {
                return false
            }
            let getRoleChild=function(roleInfo) {
                return roleInfo.some(item => {
                    if (item.roleName != null && item.roleName === roleName) {
                        return true
                    }
                    if (item.children != null) {
                        return getRoleChild(item.children, roleName)
                    }
                    return false
                })
            }
            return getRoleChild(roleInfo)
        }
    }
}