const Receivables = () => import(/* webpackChunkName: "receivables" */ "@/views/receivables")
const ReceivablesList = () => import(/* webpackChunkName: "receivables" */ "@/views/receivables/receivablesList")
const ProjectConfirmation = () => import(/* webpackChunkName: "receivables" */ "@/views/receivables/projectConfirmation")
const ClosedLoopEdit = () => import(/* webpackChunkName: "receivables" */ "@/views/receivables/closedLoopEdit")
const ReceivablesListDetail = () => import(/* webpackChunkName: "receivables" */ "@/views/receivables/receivablesListDetail")
const AccountsReceivableSummary = () => import(/* webpackChunkName: "receivables" */ "@/views/receivables/accountsReceivableSummary")
const ProjectReceivableDetail = () => import(/* webpackChunkName: "receivables" */ "@/views/receivables/projectReceivableDetail")
export default[
    {
        path:'/receivables',
        name:'Receivables',
        meta: {
            index: 3,
            title: "应收款"
        },
        component:Receivables
    },
    {
        path:'/receivables/receivablesList',
        name:'ReceivablesList',
        meta: {
            index: 4,
            title: "应收款列表"
        },
        component:ReceivablesList
    },
    {
        path:'/receivables/receivablesListDetail/:id',
        name:'ReceivablesListDetail',
        meta: {
            index: 6,
            title: "应收款列表详情"
        },
        component:ReceivablesListDetail
    },
    {
        path:'/receivables/receivablesListDetail/projectConfirmation/:id',
        name:'ProjectConfirmation',
        meta: {
            index: 7,
            title: "项目确认"
        },
        component:ProjectConfirmation
    },
    {
        path:'/receivables/receivablesListDetail/closedLoopEdit/:id',
        name:'ClosedLoopEdit',
        meta: {
            index: 7,
            title: "流程闭环"
        },
        component:ClosedLoopEdit
    },
    {
        path:'/receivables/accountsReceivableSummary',
        name:'AccountsReceivableSummary',
        meta: {
            index: 4,
            title: "应收款汇总"
        },
        component:AccountsReceivableSummary
    },{
        path:'/receivables/accountsReceivableSummary/:id',
        name:'ProjectReceivableDetail',
        meta:{
            index:5,
            title:'项目详情'
        },
        component:ProjectReceivableDetail
    }
    
]