import uniappApi from "@/nvwa/versionLast/uniApp/uniappApi";

export default {
    makePhoneCall(param) {
        window.location.href = "tel:"+param.phoneNumber;
    },
    sendMsg(param) {
        window.location.href = "sms:"+param.phoneNumber;
    },
    openBrowser(param){
        window.open(param.url)
    },
    openFile(param){
        window.open(param.url)
    },
    getWindowInfo(param) {
        if (param.success) {
            param.success({
                statusBarHeight: 0
            })
        }
    },
    openAppAuthorizeSetting() {

    }
}
