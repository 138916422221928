import request from '@/api/http'
//接口排查断点
const homeEconomicsOrder = {
    //物业列表
    getPropertyList(params, callback) {
        return request.post(process.env.BASE_URL + `/lvJingAPI/homemaking/property/paging`, params, callback)
    },
    //客户列表及详情
    getCustomerList(params, callback) {
        return request.post(process.env.BASE_URL + `/lvJingAPI/homemaking/customer/paging`, params, callback)
    },
    //添加客户
    insertCustomer(params, callback) {
        return request.post(process.env.BASE_URL + `/lvJingAPI/homemaking/customer/insert`, params, callback)
    },
    //编辑客户
    updateCustomer(params, callback) {
        return request.post(process.env.BASE_URL + `/lvJingAPI/homemaking/customer/update`, params, callback)
    },
    //删除客户
    deleteCustomer(params, callback) {
        return request.post(process.env.BASE_URL + `/lvJingAPI/homemaking/customer/delete/`+params.id, {}, callback)
    },
    //订单列表及详情
    homeEconomicsOrderList(params, callback) {
        return request.post(process.env.BASE_URL + `/lvJingAPI/homemaking/order/getOrderListDetail`, params, callback)
    },
    //添加订单
    insertHomeEconomicsOrder(params, callback) {
        return request.post(process.env.BASE_URL + `/lvJingAPI/homemaking/order/insert`, params, callback)
    },
    //完结订单
    insertSettlement(params, callback) {
        return request.post(process.env.BASE_URL + `/lvJingAPI/homemaking/order/insertSettlement`, params, callback)
    },
    //关闭订单
    closeHomeEconomicsOrder(params, callback) {
        return request.post(process.env.BASE_URL + `/lvJingAPI/homemaking/order/close`, params, callback)
    },
}
export default homeEconomicsOrder