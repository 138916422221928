import nwApi from '../nwApi'

export default function (param) {
    let {success,fail}=param
    if(fail){
        fail({
            code:-1,
            msg:'当前设备不支持微信登陆'
        })
    }
}
