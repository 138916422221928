import navigation from './modules/navigation'
import request from './modules/request'
import location from './modules/location'
import system from './modules/system'
import analytics from './modules/analytics'
import push from './modules/push'
import permission from './modules/permission'
import weChat from './modules/weChat'
import windowConfig from './modules/windowConfig'

export default {
    ...navigation,
    ...system,
    request,
    ...location,
	permission,
    analytics,
    weChat,
    push,
    windowConfig,
}
