import request from '@/api/http'
//接口排查断点
const invoice = {
    getInvoicePayBindInfoList(params,callback){
        return request.post(process.env.BASE_URL+`/project/getProjectListDetail`, params,callback)
    },
    queryInvoiceInfo(params,callback){
        return request.post(process.env.BASE_URL+`/invoice/queryInvoiceInfo`, params,callback)
    },
    queryInvoice(params,callback){
        return request.post(process.env.BASE_URL+`/invoice/queryInvoice`, params,callback)
    },
    queryInvoiceReceivables(params,callback){
        return request.post(process.env.BASE_URL+`/invoice/queryInvoiceReceivables`, params,callback)
    },
    addInvoice(params,callback){
        return request.post(process.env.BASE_URL+`/invoice/addInvoice`, params,callback)
    },
    queryTaxpayer(params,callback){
        return request.post(process.env.BASE_URL+`/invoice/queryTaxpayer`, params,callback)
    },
    updateInvoice(params,callback){
        return request.post(process.env.BASE_URL+`/invoice/updateInvoice`, params,callback)
    },
    getCompanyList(params,callback){
        return request.post(process.env.BASE_URL+`/invoice/getCompanyList`, params,callback)
    },
}


export default invoice