const Examination = () => import(/* webpackChunkName: "examination" */ "@/views/examination")
const OnlineExamination = () => import(/* webpackChunkName: "examination" */ "@/views/examination/onlineExamination")
const ExaminationDetail = () => import(/* webpackChunkName: "examination" */ "@/views/examination/examinationDetail")
const ExaminationPage = () => import(/* webpackChunkName: "examination" */ "@/views/examination/examinationPage")
const ExaminationRecord = () => import(/* webpackChunkName: "examination" */ "@/views/examination/examinationRecord")
const AnswerDetails = () => import(/* webpackChunkName: "examination" */ "@/views/examination/answerDetails")
const WrongTopicTraining = () => import(/* webpackChunkName: "examination" */ "@/views/examination/wrongTopicTraining")
const AnswerResults = () => import(/* webpackChunkName: "examination" */ "@/views/examination/answerResults")
export default[
    {
        path:'/examinationCenter',
        name:'Examination',
        meta: {
            index: 3,
            title: "考试中心"
        },
        component:Examination
    },
    {
        path:'/examinationCenter/onlineExamination',
        name:'OnlineExamination',
        meta: {
            index: 4,
            title: "在线考试"
        },
        component:OnlineExamination
    },
    {
        path:'/examinationCenter/examinationRecord',
        name:'ExaminationRecord',
        meta: {
            index: 6,
            title: "考试记录"
        },
        component:ExaminationRecord
    },
    {
        path:'/examinationCenter/wrongTopicTraining',
        name:'WrongTopicTraining',
        meta: {
            index: 4,
            title: "错题训练"
        },
        component:WrongTopicTraining
    },
    {
        path:'/examinationCenter/answerDetails',
        name:'AnswerDetails',
        meta: {
            index: 7,
            title: "答题详情"
        },
        component:AnswerDetails
    },
    {
        path:'/examinationCenter/onlineExamination/examinationDetail',
        name:'ExaminationDetail',
        meta: {
            index: 5,
            title: "考试详情"
        },
        component:ExaminationDetail
    },
    {
        path:'/examinationCenter/onlineExamination/examinationDetail/examinationPage',
        name:'ExaminationPage',
        meta: {
            index: 5,
            title: "在线考试"
        },
        component:ExaminationPage
    },
    {
        path:'/examinationCenter/onlineExamination/answerResults',
        name:'AnswerResults',
        meta: {
            index: 5,
            title: "考试结果"
        },
        component:AnswerResults
    }
]