var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banSelect" },
    [
      _c(
        "van-notify",
        {
          style: { marginTop: _vm.notifyOptions.marginTop + "px" },
          attrs: {
            value: _vm.notifyOptions.show,
            type: _vm.notifyOptions.type,
          },
        },
        [
          _c("van-icon", {
            staticStyle: { "margin-right": "4px" },
            attrs: { name: "bell" },
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.notifyOptions.message))]),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isPc
        ? _c(
            "transition",
            {
              attrs: {
                "appear-class": "van-fade",
                "enter-class": _vm.transition.enterClass,
                "enter-active-class": _vm.transition.enterActiveClass,
                "enter-to-class": _vm.transition.enterToClass,
                "leave-class": _vm.transition.leaveClass,
                "leave-active-class": _vm.transition.leaveActiveClass,
                "leave-to-class": _vm.transition.leaveToClass,
                mode: _vm.transition.mode,
              },
            },
            [
              _c(
                "keep-alive",
                { attrs: { include: _vm.includeList } },
                [
                  _c("router-view", {
                    class: [{ "is-ios-safe-area": _vm.isIOS }, "appBody"],
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "phone_tip" }, [
            _c("div", [_vm._v("\n      请使用手机打开\n    ")]),
          ]),
      _vm._v(" "),
      _vm.$loginStatus.isDebug
        ? _c("div", {
            ref: "debugBtRef",
            staticClass: "debug_bt",
            on: {
              click: function ($event) {
                _vm.debugBtPopoverShow = !_vm.debugBtPopoverShow
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "nut-popover",
        {
          attrs: { position: "top", root: _vm.$refs.debugBtRef },
          model: {
            value: _vm.debugBtPopoverShow,
            callback: function ($$v) {
              _vm.debugBtPopoverShow = $$v
            },
            expression: "debugBtPopoverShow",
          },
        },
        [
          _c(
            "div",
            [
              _c("van-field", {
                attrs: { label: "主管账号", disabled: "", clickable: "" },
                on: {
                  click: function ($event) {
                    return _vm.changeLogin("projectManager")
                  },
                },
              }),
              _vm._v(" "),
              _c("van-field", {
                attrs: { label: "区域综管", disabled: "", clickable: "" },
                on: {
                  click: function ($event) {
                    return _vm.changeLogin("areaChange")
                  },
                },
              }),
              _vm._v(" "),
              _c("van-field", {
                attrs: { label: "区域经理", disabled: "", clickable: "" },
                on: {
                  click: function ($event) {
                    return _vm.changeLogin("areaManager")
                  },
                },
              }),
              _vm._v(" "),
              _c("van-field", {
                attrs: { label: "超级管理员", disabled: "", clickable: "" },
                on: {
                  click: function ($event) {
                    return _vm.changeLogin("superManager")
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }