import request from '@/api/http'

const project = {
    // post提交
    getProjectConfigList (params,callback) {
        let callbackInterceptor=(data,outSideData)=>{
            if(data==='fail'){
                callback(data,outSideData)
            }else{
                callback(data.map(item=>{
                    let projectManager=item.projectManager
                    if(item.projectRelList==null||item.projectRelList.length<1){
                        item.projectManager='暂无绑定主管'
                    }else{
                        item.projectManager=item.projectRelList[0].employeeName
                        for(let i=1;i<item.projectRelList.length;i++){
                            item.projectManager+='/'+item.projectRelList[i].employeeName
                        }
                    }
                    if(item.projectManager==='暂无绑定主管'&&projectManager!=null){
                        item.projectManager=projectManager
                    }
                    return item
                }),outSideData)
            }
        }
        return request.post(process.env.BASE_URL+`/project/getProjectConfigList`, params,callbackInterceptor)
    },
    // 获取甲方公司列表
    getOwnerCompanyList(params,callback){
        return request.post(process.env.BASE_URL+`/project/getOwnerCompanyList`, params,callback)
    },
    getProjectNews (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getProjectNews`, params,callback)
    },
    updateProjectConfig (params,callback) {
        return request.post(process.env.BASE_URL+`/project/updateProjectConfig`, params,callback)
    },
    updateProjectSite (params,callback) {
        params.method="PUT"
        return request.post(process.env.BASE_URL+`/project/updateProjectSite`, params,callback)
    },
    addProjectApply (params,callback) {
        return request.post(process.env.BASE_URL+`/project/addProjectApply`, params,callback)
    },
    getApplyProjectList (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getApplyProjectList`, params,callback)
    },
    getAuditProjectList (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getAuditProjectList`, params,callback)
    },
    projectAudit (params,callback) {
        return request.post(process.env.BASE_URL+`/project/projectAudit`, params,callback)
    },
    projectSignOutApply (params,callback) {
        return request.post(process.env.BASE_URL+`/project/projectSignOutApply`, params,callback)
    },
    addServiceMargin (params,callback) {
        return request.post(process.env.BASE_URL+`/project/addServiceMargin`, params,callback)
    },
    getWorkProcessList (params,callback) {
        return request.post(process.env.BASE_URL+`/lvjing/flow/process/initiator`, params,callback)
    },
    getWorkCcProcessList (params,callback) {
        return request.post(process.env.BASE_URL+`/lvjing/flow/task/ccList`, params,callback)
    },
    getWaitProcessList (params,callback) {
        return request.post(process.env.BASE_URL+`/lvjing/flow/task/assigned`, params,callback)
    },
    processDetail (params,callback) {
        return request.post(process.env.BASE_URL+`/project/processDetail`, params,callback)
    },
    //ss
    getProjectBaseNews (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getProjectBaseNews`, params,callback)
    },
    getLastProjectContractNews (params,callback) {
        return request.post(process.env.BASE_URL+`/project/getLastProjectContractNews`, params,callback)
    },
    getProjectOverview(params,callback){
        return request.post(process.env.BASE_URL+`/project/getProjectOverview`,params,callback)
    },
    getOverviewMap(params,callback){
        return request.post(process.env.BASE_URL+`/project/getOverview`,params,callback)
    },
    getEmployeeOverview(params,callback){
        return request.post(process.env.BASE_URL+`/project/getEmployeeOverview`,params,callback)
    },
    getRepairList(params,callback){
        return request.get(process.env.BASE_URL+`/equipment/repair/pageList`,params,callback)
    },
    getProjectList(params,callback){
        return request.post(process.env.BASE_URL+`/project/getProjectList`,params,callback)

    }
}

export default project
