import request from '@/api/http'

const news = {
    getNewsList(params, callback) {
        return request.get(
            params.type == '新闻' ? `https://publicapp.oss-cn-hangzhou.aliyuncs.com/lvjingAppNews/jsonData/newsRefresh.json` :
                params.type == '信息' ? `https://publicapp.oss-cn-hangzhou.aliyuncs.com/lvjingAppNews/jsonData/infoRefresh.json` :
                    params.type == '节日' ? `https://publicapp.oss-cn-hangzhou.aliyuncs.com/lvjingAppNews/jsonData/festivalRefresh.json` :
                        params.type == '其他' ? `https://publicapp.oss-cn-hangzhou.aliyuncs.com/lvjingAppNews/jsonData/otherRefresh.json` :
                            ``, params, callback)
    },
}


export default news