const HomeEconomics = () => import(/* webpackChunkName: "homeEconomics" */ "@/views/homeEconomics")
const HomeEconomicsOrderList = () => import(/* webpackChunkName: "homeEconomics" */ "@/views/homeEconomics/homeEconomicsOrderList")
const CustomerManagement = () => import(/* webpackChunkName: "homeEconomics" */ "@/views/homeEconomics/customerManagement")
const HomeEconomicsOrderAdd = () => import(/* webpackChunkName: "homeEconomics" */ "@/views/homeEconomics/homeEconomicsOrderAdd")
const HomeEconomicsCustomerDetail = () => import(/* webpackChunkName: "homeEconomics" */ "@/views/homeEconomics/homeEconomicsCustomerDetail")
const HomeEconomicsOrderDetail = () => import(/* webpackChunkName: "homeEconomics" */ "@/views/homeEconomics/homeEconomicsOrderDetail")
const HomeEconomicsOrderCompletion = () => import(/* webpackChunkName: "homeEconomics" */ "@/views/homeEconomics/homeEconomicsOrderCompletion")
export default[
    {
        path:'/homeEconomics',
        name:'HomeEconomics',
        meta: {
            index: 3,
            title: "家政模块"
        },
        component:HomeEconomics
    },
    {
        path:'/homeEconomics/homeEconomicsOrderList',
        name:'HomeEconomicsOrderList',
        meta: {
            index: 4,
            title: "订单管理"
        },
        component:HomeEconomicsOrderList
    },
    {
        path:'/homeEconomics/customerManagement',
        name:'CustomerManagement',
        meta: {
            index: 4,
            title: "客户管理"
        },
        component:CustomerManagement
    },
    {
        path:'/homeEconomics/homeEconomicsOrderAdd',
        name:'HomeEconomicsOrderAdd',
        meta: {
            index: 5,
            title: "新建订单"
        },
        component: HomeEconomicsOrderAdd
    },
    {
        path:'/homeEconomics/homeEconomicsCustomerDetail/:type/:id?',
        name:'HomeEconomicsCustomerDetail',
        meta: {
            index: 5,
            title: "客户详情"
        },
        component: HomeEconomicsCustomerDetail
    },
    {
        path:'/homeEconomics/homeEconomicsOrderDetail/:id',
        name:'HomeEconomicsOrderDetail',
        meta: {
            index: 5,
            title: "订单详情"
        },
        component: HomeEconomicsOrderDetail
    },
    {
        path:'/homeEconomics/homeEconomicsOrderCompletion/:id',
        name:'HomeEconomicsOrderCompletion',
        meta: {
            index: 4,
            title: "订单完结"
        },
        component: HomeEconomicsOrderCompletion
    },
    
]