export default {
    makePhoneCall(param) {
        window.location.href = "tel:"+param.phoneNumber;
    },
    sendMsg(param) {
        window.location.href = "sms:"+param.phoneNumber;
    },
    openBrowser(param){
        window.location.href = "browser:"+param.url;
    },
    openFile(param){
        window.location.href = "browser:"+param.url;
    },
    getWindowInfo(param) {
        if (param.success) {
            param.success({
                statusBarHeight: 0
            })
        }
    }
}
