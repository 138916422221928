/***    主页    ***/
const login = () => import(/* webpackChunkName: "home" */ "@/views/home/Login")
const afterLogin = () => import(/* webpackChunkName: "home" */ "@/views/home/AfterLogin")
const home = () => import(/* webpackChunkName: "home" */ "@/views/home/Home")
const homeMsg = () => import(/* webpackChunkName: "home" */ "@/views/home/HomeMsg")
const homeNews = () => import(/* webpackChunkName: "home" */ "@/views/home/HomeNews")
const TempHomeNews = () => import(/* webpackChunkName: "home" */ "@/views/home/TempHomeNews")
const homePhoneBook = () => import(/* webpackChunkName: "home" */ "@/views/home/HomePhoneBook")
const homeWorkspace = () => import(/* webpackChunkName: "home" */ "@/views/home/HomeWorkspace")
const homeMessage = () => import(/* webpackChunkName: "home" */ "@/views/home/HomeMessage")
const homeMessageDetail = () => import(/* webpackChunkName: "home" */ "@/views/home/HomeMessageDetail")
const homeMine = () => import(/* webpackChunkName: "home" */ "@/views/home/HomeMine")
const SearchEmployee = () => import(/* webpackChunkName: "home" */ "@/views/home/SearchEmployee")
const EditPsd = () => import(/* webpackChunkName: "home" */ "@/views/home/EditPsd")
const HomeSet = () => import(/* webpackChunkName: "home" */ "@/views/home/HomeSet")
const TempEmployeeHome = () => import(/* webpackChunkName: "home" */ "@/views/home/TempEmployeeHome")
const LearnCenter = () => import(/* webpackChunkName: "home" */ "@/views/learnCenter/LearnCenter")
const Tucao = () => import(/* webpackChunkName: "home" */ "@/views/home/Tucao")
const ExamMiddlePage = () => import(/* webpackChunkName: "home" */ "@/views/home/ExamMiddlePage")

export default [
    {
        path: '/',
        name: 'login',
        meta: {
            index: 0,
            title: "登录",
            useApp: true
        },
        component: login,
    },
    {
        path: '/afterLogin',
        meta: {
            index: 99,
            title: "登录"
        },
        name: 'afterLogin',
        component: afterLogin
    },
    {
        path: '/tucao/:type',
        meta: {
            index: 99,
            title: "反馈",
            forceUseApp:true
        },
        name: 'Tucao',
        component: Tucao
    },
    {
        path:'/examMiddlePage',
        meta:{
            index: 3,
            title:'考试中间页',
        },
        name: 'ExamMiddlePage',
        component: ExamMiddlePage

    },
    {
        path: '/home',
        name: 'home',
        meta: {
            index: 1,
            useApp: true
        },
        component: home,
        children: [
            {
                path: 'homeMsg',
                name: 'homeMsg',
                meta: {
                    index: 2,
                    title: "消息"
                },
                component: homeMsg
            },
            {
                path: 'home',
                name: 'homeNews',
                meta: {
                    index: 2,
                    title: "主页"
                },
                component: homeNews
            }, {
                path: 'phoneBook',
                name: 'homePhoneBook',
                meta: {
                    index: 2,
                    title: "通讯录"
                },
                component: homePhoneBook
            },
            {
                path: 'workspace',
                name: 'homeWorkspace',
                meta: {
                    index: 2,
                    title: "工作台"
                },
                component: homeWorkspace
            },
            {
                path: 'message',
                name: 'homeMessage',
                meta: {
                    index: 2,
                    title: "消息"
                },
                component: homeMessage,
            },
            {
                path: 'mine',
                name: 'homeMine',
                meta: {
                    index: 2,
                    title: "我的"
                },
                component: homeMine
            }
        ]
    },
    {
        path: '/messageDetail/:type',
        name: 'homeMessageDetail',
        meta: {
            index:3,
            title: '消息详情'
        },
        component: homeMessageDetail,
    },
    {
        path: '/temp-home',
        name: 'TempEmployeeHome',
        meta: {
            index: 1,
            useApp: true
        },
        component: TempEmployeeHome,
        children: [
            {
                path: 'homeMsg',
                name: 'homeMsg',
                meta: {
                    index: 2,
                    title: "消息"
                },
                component: homeMsg
            },
            {
                path: '',
                name: 'TempHomeNews',
                meta: {
                    index: 2,
                    title: "主页"
                },
                component: TempHomeNews
            }, {
                path: 'phoneBook',
                name: 'homePhoneBook',
                meta: {
                    index: 2,
                    title: "通讯录"
                },
                component: homePhoneBook
            },
            {
                path: 'workspace',
                name: 'homeWorkspace',
                meta: {
                    index: 2,
                    title: "工作台"
                },
                component: homeWorkspace
            },
            {
                path: 'message',
                name: 'homeMessage',
                meta: {
                    index: 2,
                    title: "消息"
                },
                component: homeMessage
            },
            {
                path: 'learnCenter',
                name: 'LearnCenter',
                meta: {
                    index: 2,
                    title: "学习中心"
                },
                component: LearnCenter
            },
            {
                path: 'mine',
                name: 'homeMine',
                meta: {
                    index: 2,
                    title: "我的"
                },
                component: homeMine
            }
        ]
    },
    {
        path: '/searchEmployee',
        name: 'SearchEmployee',
        meta: {
            index: 8,
            title: "查找通讯录"
        },
        component: SearchEmployee
    },
    {
        path: '/editPsd',
        name: 'EditPsd',
        meta: {
            index: 99,
            title: "修改密码"
        },
        component: EditPsd
    },
	{
	    path: '/homeSet',
	    name: 'HomeSet',
	    meta: {
	        index: 99,
	        title: "设置"
	    },
	    component: HomeSet
	}
]
