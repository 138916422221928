import request from '@/api/http'
const contractHandover = {
    //获取合同移交列表
    getContractHandover(params,callback){
        return request.post(process.env.BASE_URL+`/lvJingAPI/contractTransfer/getListDetail`, params,callback)
    },
    //添加合同移交记录
    addContractHandover(params,callback){
        return request.post(process.env.BASE_URL+`/lvJingAPI/contractTransfer/insert`, params,callback)
    },
}
export default contractHandover