import request from '@/api/http'

const message = {
    getMessageList(params,callback){
        return request.post(process.env.BASE_URL+`/project/getMyWaitProcessList`, params,callback)
    },
    messageReadFun(params,callback){
        return request.post(process.env.BASE_URL+`/project/readMyWaitProcessList`, params,callback)
    },
    getMessageNum(params,callback){
        return request.post(process.env.BASE_URL+`/project/getAllWaitProcessList`, params,callback)
    },
}


export default message