export default {
    data() {
        return {
            CONSTANTS: {
                ACTION_BAR_REF: 'NutActionBarRef',
                TAB_BAR_REF: 'NutTabBarRef',
                TAB_REF: 'NutTabRef',
                PULL_REFRESH_REF: 'NutPullRefreshRef'
            },
            WINDOW_CONFIG: {
                actionBarHeight: 0,
                topStickyHeight: 0,
                contentHeight: 0,
                tabBarHeight: 0,
                tabHeight: 0
            },
            PageHeight:window.innerHeight
        }
    },
    mounted() {
        let actionBarRef = this.$refs[this.CONSTANTS.ACTION_BAR_REF]
        if (actionBarRef !== undefined) {
            actionBarRef.registerChangeListener(()=>{
                this.$refreshHeight()
            })
        }
        this.$refreshHeight()
    },
    methods: {
        $pullRefresh() {
            let pullRefreshRef = this.$refs[this.CONSTANTS.PULL_REFRESH_REF]
            if (pullRefreshRef !== undefined) {
                pullRefreshRef.refresh()
                return
            }
            console.error('请先定义下拉刷新的ref')
        },
        $refreshHeight(){
            let actionBarRef = this.$refs[this.CONSTANTS.ACTION_BAR_REF]
            if (actionBarRef !== undefined) {
                this.WINDOW_CONFIG.actionBarHeight = actionBarRef.actionBarHeight
                this.WINDOW_CONFIG.topStickyHeight = actionBarRef.topStickyHeight
            }
            let tabBarRef = this.$refs[this.CONSTANTS.TAB_BAR_REF]
            if (tabBarRef !== undefined) {
                this.WINDOW_CONFIG.tabBarHeight = tabBarRef.$el.clientHeight
                console.log(this.WINDOW_CONFIG.tabBarHeight)
            }
            let tabRef = this.$refs[this.CONSTANTS.TAB_REF]
            if (tabRef !== undefined) {
                this.WINDOW_CONFIG.tabHeight = tabRef.$el.clientHeight
            }
            this.WINDOW_CONFIG.contentHeight = this.PageHeight - this.WINDOW_CONFIG.topStickyHeight - this.WINDOW_CONFIG.tabBarHeight - this.WINDOW_CONFIG.tabHeight
            console.log(this.WINDOW_CONFIG.contentHeight)
        },
        $hideStatusBar(show) {
            this.$nw.windowConfig({
                params: {
                    hideStatusBar:show
                },
                success() {
                }
            })
        }
    }
}