const EquipmentInventoryList = () => import(/* webpackChunkName: "equipmentInventory" */ "@/views/equipmentInventory/equipmentInventoryList")
const EquipmentInventoryDetail = () => import(/* webpackChunkName: "equipmentInventory" */ "@/views/equipmentInventory/equipmentInventoryDetail")


export default [
    {
        path: '/equipmentInventory/equipmentInventoryList',
        name: 'EquipmentInventoryList',
        meta: {
            index: 3,
            title: "设备盘点"
        },
        component: EquipmentInventoryList,
        props: true
    },
    {
        path: '/equipmentInventory/equipmentInventoryDetail/:id',
        name: 'EquipmentInventoryDetail',
        meta: {
            index: 4,
            title: "盘点详情"
        },
        component: EquipmentInventoryDetail,
        props: true
    },
]