import router from './router'
import App from './app.vue';
import store from './store'
import echarts from 'echarts'

store.dispatch('loginStatus/init')
store.commit('init')
// 引入 Vconsole
import Vconsole from 'vconsole'

// 所有环境均使用
// new Vconsole()

// 在 test 环境才使用
// process.env.NODE_ENV === 'development' ? new Vconsole : ''

// *************      组件库         *******
import Vant from 'vant';
// import 'vant/lib/index.css';
Vue.prototype.$echarts = echarts
Vue.use(Vant);
import {Lazyload, ImagePreview, Dialog, Toast} from 'vant'

Vue.use(Lazyload).use(ImagePreview).use(Dialog)
import './assets/global.css'

Dialog.setDefaultOptions({
    width: "250px",
    transition: "van-slide-up",
    confirmButtonColor: "#00C0A6"
})
Toast.setDefaultOptions("loading", {
    duration: 0,
    forbidClick: true
})

// window.eventBus = new Vue();
window.eventBus = new class{
    constructor(){
        this.events = {}
    }
    $on(name, callback){
        if(!this.events[name]){
            this.events[name] = []
        }
        let channel = new BroadcastChannel(name);
        channel.onmessage = function(event) {
            console.log('callback',name,event.data)
            callback(event.data); // { key: 'value' }
        };
        this.events[name].push({
            callback,
            channel
        })
    }
    $once(name, callback){
        if(!this.events[name]){
            this.events[name] = []
        }
        let channel = new BroadcastChannel(name);
        channel.onmessage = (event)=> {
            callback(event.data); // { key: 'value' }
            this.$off(name,callback)
        };
        this.events[name].push({
            callback,
            channel
        })
    }
    $off(name, callback){
        if(!this.events[name]){
            return
        }
        if(!callback){
                this.events[name].forEach(item => {
                    item.channel.close()
                })
            this.events[name] = []
            return
        }
        this.events[name] = this.events[name].filter(item => {
            if(item.callback === callback){
                item.channel.close()
                return false
            }
            return true
        })
    }

    $emit(name, data){
        let channel = new BroadcastChannel(name);
        channel.postMessage(data)
        channel.close()
    }

    clear(){
        for(let key in this.events){
            this.events[key].forEach(item => {
                item.channel.close()
            })
        }
    }
}

import api from './api'
import nw from '@/nvwa/versionLast'

Vue.prototype.$fetch = api
Vue.prototype.$nw = nw
Vue.prototype.$notify = (options) => {
    if (!options) {
        options = {}
    }
    if (typeof options === 'string') {
        options = {
            message: options
        }
    }
    store.commit('showNotify', options)

}
Vue.prototype.$notify.clear = () => {
}

// 剪切板
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard)

import actionBar from '@/components/NutActionBar'
import uniappApi from "@/nvwa/versionLast/uniApp/uniappApi";

Vue.component('action-bar', actionBar)
Vue.mixin({
    data(){
        return{
            $activated: null
        }
    },
    created() {
        if(this.$options.activated){
            console.log('activated',this.$options.activated)
            this.$activated = this.$options.activated[0]
            document.addEventListener('uniappPageShow', function () {
                console.log('uniappPageShow')
                if(this.$activated){
                    this.$activated()
                }
            })
        }
    }
})
// 加入到vue实例中
const vue_ins=new Vue({
    el: '#app',
    router: router,
    store,
    render: (c) => {
        return c(App);
    },
    methods: {
        notify(options = {}) {
            store.commit('showNotify', options)
        },
    }
});

uniappApi.plusCallPre(() => {

    document.addEventListener('hide', function () {
        console.log('hide')

    })
    document.addEventListener('pause', function () {
        console.log('pause')

    })
    document.addEventListener('resume', function () {
        console.log('resume')
    })

    document.addEventListener("background", function(){
        console.log('background')
        //应用切换到后台运行
    }, false);
    document.addEventListener("foreground", function(){
        console.log('foreground')
        //应用切换到后台运行
    }, false);
    document.addEventListener("newintent", function(e){
        //新意图
        console.log('newintent',e)

    }, false);
})
export default vue_ins

