import request from '@/api/http'
const learnCenter = {
    insert(params,callback){
        return request.post(process.env.BASE_URL+`/lvJingAPI/buriedPointInsert`, params,callback)
    },
    queryTimes(params,callback){
        return request.post(process.env.BASE_URL+`/lvJingAPI/buriedPoint`, params,callback)
    }
}


export default learnCenter