import request from '@/api/http'

const groupchecker = {
    getUserGroup(params, callback) {
        return request.get(process.env.BASE_URL + `/employee/groups/groupMemberList`, params, callback)
    },
    //添加群组
    addGroup(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/groups/add`, params, callback)
    },
    //删除群组
    deleteGroup(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/groups/` + params.id, params, callback)
    },
    //更改群组
    updateGroup(params, callback) {
        return request.post(process.env.BASE_URL + `/employee/groups/update`, params, callback)
    },
    //删除群组成员
    deleteGroupMember(params, callback){
        return request.post(process.env.BASE_URL + `/employee/groups/member/` + params.id, params, callback)
    },
}
export default groupchecker