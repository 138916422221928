import Router from "@/router";
import uniappApi from '../uniappApi'

export default {
    navigateTo(param) {
        let reg = /(http|https):\/\/([\w.]+\/?)\S*/;
        param.route=param.url
        if (!reg.test(param.url)) {
            // Router.push(param.url)
            console.log(location.href.split('#')[0]+'#'+param.url)
            uni.navigateTo({
                url: '/pages/home/home?src='+encodeURI(param.url)
            })
        }else{
            uni.navigateTo({
                url: '/pages/webview/webview?src='+encodeURI(param.url)
            })
        }
    },
    redirectTo(param) {
    },
    navigateBack(param){
        console.log('navigateBack')
        // Router.go(-1)
        uni.navigateBack()
    },
    reLaunch(param) {
    },
    parseUrl(url) {
        if (url.indexOf('?') === -1) {
            return url
        } else {
            var result = {};
            var query = url.split("?");
            var queryArr = query[1].split("&");
            queryArr.forEach(function (item) {
                var value = item.split("=")[1];
                var key = item.split("=")[0];
                result[key]=value;
            });
            localStorage.setItem("routerArgus", JSON.stringify(result))
            return query[0]

        }
    },
    routerArgus() {
        return JSON.parse(localStorage.getItem("routerArgus"))
    },
    hideSystemNavigation(){

    },
    showSystemNavigation(){

    }
}
