import uniappApi from '../uniappApi'
import uniPush from "@/api/modules/uniPush";
// https://ask.dcloud.net.cn/article/36730
export default {
    setAlias(param) {
		let cid = plus.push.getClientInfo().clientid
		let switchKey=JSON.parse(localStorage.getItem(`userInfo`)).id
		let switch_push=localStorage.getItem(`switch_push${switchKey}`)
		if( param.params.forceOpen || switch_push===null){
			const {type, aliasType, alias} = param.params
			uniappApi.plusCallPre(() => {
			    uniPush.cidBindAlias({
			        "appId": "__UNI__ABAEA3A",
			        "aliasList": [{"cid": cid, "alias": alias}]
			    }, (res) => {
					localStorage.setItem(`switch_push${switchKey}`,true)
			        console.log('cidBindAlias', res)
			    })
			})
		}
        
    },
    removeAlias(param) {
        const {aliasType, alias} = param.params
        uniappApi.plusCallPre(() => {
            let cid = plus.push.getClientInfo().clientid
            uniPush.unboundAlias({
                "appId": "__UNI__ABAEA3A",
                "aliasList": [{"cid": cid, "alias": alias}]
            }, (res) => {
				let switchKey=JSON.parse(localStorage.getItem(`userInfo`)).id
				localStorage.setItem(`switch_push${switchKey}`,false)
                console.log('unboundAlias', res)
            })
        })
    },
    getAllMessage(callback) {
        uniappApi.plusCallPre(() => {
            callback(plus.push.getAllMessage())
        })
    }
}
