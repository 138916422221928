<template>
    <div>
        <action-bar background-color="rgba(52,52,52,0)"
                    :border="false"
                    :title="selfActionBar.title"
                    :ref="CONSTANTS.ACTION_BAR_REF"
                    :backgroundImg="selfActionBar.backgroundImg"></action-bar>
        <!--<img class="background_img"-->
        <!--:src="backgroundImg">-->
        <img class="background_img"
             src="/static/img/others/employeeCard/background_img.jpg">
        <div class="title">{{employeeInfo.employeeName}}</div>
        <div class="name">
            <div>{{employeeInfo.orgName}}</div>
            <div>{{employeeInfo.positionType}}</div>
        </div>
        <div class="avatar_box">
            <img class="avatar"
                 src="https://publicapp.oss-cn-hangzhou.aliyuncs.com/avatar/head1.jpg">
        </div>
        <div class="org">
            <div class="label">所属部门</div>
            <div class="cell_body">{{employeeInfo.orgName}}</div>
        </div>
        <div class="position">
            <div class="label left">职位</div>
            <div class="cell_body left">{{employeeInfo.positionType}}</div>
        </div>
        <div class="tab_box">
            <div class="tab" @click="sendMsg">
                <img class="bt"
                     src="/static/img/others/employeeCard/msg.png"/>
                <div class="text">发消息</div>
            </div>
            <div class="tab" @click="phone">
                <img class="bt"
                     src="/static/img/others/employeeCard/phone.png"/>
                <div class="text">打电话</div>
            </div>
            <div class="tab" @click="email">
                <img class="bt"
                     src="/static/img/others/employeeCard/email.png"/>
                <div class="text">发邮件</div>
            </div>
        </div>
    </div>
</template>

<script>
    import BasePage from '@/views/components/BasePage'

    export default {
        name: "EmployeeCard",
        extends: BasePage,
        data() {
            return {
                employeeId: '',
                employeeInfo: {
                    employeeName: 'XXX',
                    orgName: 'XXXXXX',
                    position: 'XXXXXXXXXXX',
                    employeeMobile: ''
                },
                backgroundImg: '/static/img/others/employeeCard/background_img.jpg',
                selfActionBar: {
                    title: "",
                    backgroundImg: ""
                }
            }
        },
        created() {
            this.$toast.loading({mask:true})
            this.employeeId = localStorage.getItem("EmployeeCardRouteParam")
            this.$fetch.employee.queryEmployeeInfoById({
                employeeId: this.employeeId,
                // start:1
            }, data => {
                if(data == 'fail'){
                  this.$toast.clear()
                }else{
                  this.$toast.clear()
                  this.employeeInfo = data
                }
            })
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll)
        },
        methods: {
            handleScroll() {
                let scrollTop = window.scrollY
                if (scrollTop > 170 - this.WINDOW_CONFIG.actionBarHeight) {
                    this.selfActionBar.title = this.employeeInfo.employeeName
                    this.selfActionBar.backgroundImg = this.backgroundImg
                } else {
                    this.selfActionBar.title = ""
                    this.selfActionBar.backgroundImg = ""
                }
            },
            sendMsg() {
                this.$nw.sendMsg({
                    phoneNumber: this.employeeInfo.employeeMobile,
                })
            },
            phone() {
                this.$nw.makePhoneCall({
                    phoneNumber: this.employeeInfo.employeeMobile,
                })
            },
            email() {
                //TODO 发邮件

                this.$notify({
                    message: '当前联系人暂无邮箱',
                    duration: 1000,
                    background: '#1989fa'
                })
            },
            back() {
                this.$nw.navigateBack()
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }
</script>

<style scoped lang="scss">
    .background_img {
        position: absolute;
        z-index: -1;
        width: 375px;
        height: 265px
    }

    .title {
        position: absolute;
        top: 120px;
        left: 36px;
        height: 30px;
        font-family: PingFangSC-Semibold;
        color: white;
        font-size: 25px;
    }

    .name {
        position: absolute;
        left: 36px;
        top: 160px;
        font-family: PingFangSCRegular;
        font-size: 13px;
        height: 15px;
        color: #fff;
    }

    .avatar_box {
        margin-top: 170px;
        padding-top: 15px;
        background-color: white;
    }

    .avatar {
        margin-left: 36px;
        width: 75px;
        height: 75px;
        border-radius: 5px;
    }

    .org {
        margin-top: 15px;
        border-top-width: 10px;
        border-color: whitesmoke;
        border-style: solid;
        padding-left: 36px;
    }

    .position {
        border-top-width: 1px;
        border-color: whitesmoke;
        border-style: solid;
        padding-left: 26px;
    }

    .label {
        color: darkgrey;
        margin-top: 15px;
    }

    .cell_body {
        margin-top: 5px;
        font-size: 18px;
        margin-bottom: 15px;
    }

    .left {
        margin-left: 10px;
    }

    .tab_box {
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 20px;
        width: 375px;
        height: 100px;
        justify-content: space-around;

        .tab {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .bt {
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }

            .text {
                margin-top: 11px;
                font-size: 13px;
                height: 13px;
                line-height: 13px;
            }
        }
    }

    .tab_bt {
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }

    .tab_div {
        margin-top: 7px;
        width: 50px;
        text-align: center;
    }
</style>
