export default {
    computed:{
        $loginStatus(){
            return this.$store.state.loginStatus
        }
    },
    methods:{
        $hasMenu(menuUrl){
            return this.$store.getters['loginStatus/hasMenu'](menuUrl)
        },
        $getMenuById(menuId){
            return this.$store.getters['loginStatus/getMenuById'](menuId)
        },
        $getRoleInfo(roleName){
            return this.$store.getters['loginStatus/getRoleInfo'](roleName)
        },
        $hasLogin(){
            return this.$store.getters['loginStatus/hasLogin']()
        }
    }
}