import nwApi from "@/nvwa/versionLast/ios/nwApi";
import uniappApi from "@/nvwa/versionLast/uniApp/uniappApi";

export default {
    makePhoneCall(param) {
        console.log("tel:")
        window.location.href = "tel:" + param.phoneNumber;
    },
    sendMsg(param) {
        window.location.href = "sms:" + param.phoneNumber;
    },
    openBrowser(param) {
        window.location.href = "browser:" + param.url;
    },
    openFile(param) {
        nwApi.call('navigation', {
            params: {
                type: 'file',
                url: param.url
            }
        }, () => {
        })
        // window.location.href = "file:"+param.url;
    },
    getWindowInfo(param) {
        if (param.success) {
            param.success({
                statusBarHeight: 0
            })
        }
    }
}
