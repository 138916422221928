/***    项目    ***/
const ProjectHome = () => import(/* webpackChunkName: "project" */ "@/views/project/ProjectHome")
const ProjectDetail = () => import(/* webpackChunkName: "project" */ "@/views/project/ProjectDetail")
const ProjectBrief = () => import(/* webpackChunkName: "project" */ "@/views/projectBrief")
const employeeDetail = () => import(/* webpackChunkName: "project" */ "@/views/projectBrief/employeeDetail")
const briefProjectDetail = () => import(/* webpackChunkName: "project" */ "@/views/projectBrief/projectDetail")
const repairDetail = () => import(/* webpackChunkName: "project" */ "@/views/projectBrief/repairDetail")
const purchaseDetail = () => import(/* webpackChunkName: "project" */ "@/views/projectBrief/purchaseDetail")

export default [
    {
        path: '/project',
        name: 'ProjectHome',
        meta: {
            index: 3,
            title: "项目管理"
        },
        component: ProjectHome,
    },
    {
        path: '/project/detail/:type',
        name: 'ProjectDetail',
        meta: {
            index: 5,
            title: "项目详情"
        },
        component: ProjectDetail,
        props:true
    },
    {
        path:'/projectBrief',
        name:'ProjectBrief',
        meta: {
            index:3,
            title: "项目简报"
        },
        component:ProjectBrief
    },
    {
        path:'/projectBrief/employeeDetail',
        name:'briefEmployeeDetail',
        meta:{
            index:4,
            title:"员工详情"
        },
        component:employeeDetail
    },
    {
        path:'/projectBrief/briefProjectDetail',
        name:'briefProjectDetail',
        meta:{
            index:4,
            title:"项目详情"
        },
        component:briefProjectDetail
    },
    {
        path:'/projectBrief/repairDetail',
        name:'repairDetail',
        meta:{
            index:4,
            title:"报修详情"
        },
        component:repairDetail
    },
    {
        path:'/projectBrief/purchaseDetail',
        name:'purchaseDetail',
        meta:{
            index:4,
            title:"采购详情"
        },
        component:purchaseDetail
    }
]