import nvwaEnv from '../nvwaEnvironment'

const nw = function () {
    let ua=nvwaEnv.UA
    ua.type= 'UNKNOW'
    if(ua.isUniApp){
        ua.type='UNI_APP'
    }else if(ua.isPc){
        ua.type='PC'
    }else if(ua.isWeChatApplet){
        ua.type='WE_CHAT_APPLET'
    }else if(ua.isWeChat){
        ua.type='WE_CHAT'
    }else if(ua.isIOS){
        ua.type='IOS'
    }else if(ua.isAndroid){
        ua.type='ANDROID'
    }else if(ua.isBlackBerry){
        ua.type="BLACK_BERRY"
    }else if(ua.isWindowPhone){
        ua.type="WINDOW_PHONE"
    }else{
        ua.type='OTHER'
    }

    if(ua.isNvwa){
        ua.type+='_NVWA'
        if(ua.isUniApp){
            return require('./uniApp').default
        }
        if (ua.isAndroid) {
            return require('./android').default
        }
        if (ua.isNvwa && ua.isIOS) {
            return require('./ios').default
        }
    }
    // if (ua.isWeChat) {
    //     return require('./weChat').default
    // }
    return require('./web').default
}

export default Object.assign(nw(), {UA: nvwaEnv.UA})
