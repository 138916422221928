import Router from '@/router/index'

export default {
    navigateTo(param) {
        // let reg = /(http|https):\/\/([\w.]+\/?)\S*/;
        // if (!reg.test(param.url)) {
        //     param.url = location.href.slice(0, location.href.indexOf('#') + 1) + this.parseUrl(param.url)
        // }
        // window.open(param.url)
        let reg=/(http|https):\/\/([\w.]+\/?)\S*/;
        if(!reg.test(param.url)){
            Router.push(param.url)
        }else{
            window.open(param.url)
        }
    },
    redirectTo(param) {
        Router.push(this.parseUrl(param.url))
    },
    reLaunch(param) {
        Router.replace(this.parseUrl(param.url))
    },
    navigateBack(index) {
        index=index?index:1
        Router.go(-index)
    },
    parseUrl(url) {
        if (url.indexOf('?') === -1) {
            return url
        } else {
            var result = {};
            var query = url.split("?");
            var queryArr = query[1].split("&");
            queryArr.forEach(function (item) {
                var value = item.split("=")[1];
                var key = item.split("=")[0];
                result[key]=value;
            });
            localStorage.setItem("routerArgus", JSON.stringify(result))
            return query[0]

        }
    },
    routerArgus() {
        return JSON.parse(localStorage.getItem("routerArgus"))
    },
    handleBack(callback){
        let state = {
            title: "title",
            url: "#"
        };
        window.history.pushState(state, "title", "#");
        window.addEventListener('popstate', callback,false);
    },
    releaseBack(){
        window.removeEventListener('popstate',null,true)
        history.back()
    },
    hideSystemNavigation(){

    },
    showSystemNavigation(){

    }
}
