import request from '@/api/http'

const receivable={
  //应收款 -编辑--保存
  updateProjectReceivablesDetail(params,callback){
    return request.post(process.env.BASE_URL+`/project/updateProjectReceivablesDetail`,params,callback)
  },
  //应收款列表
  getProjectListDetailList(params,callback){
    return request.post(process.env.BASE_URL+`/project/getProjectListDetail`,params,callback)
  },
  //应收款详情
  getProjectListDetail(params,callback){
    return request.post(process.env.BASE_URL+`/project/getProjectListDetail`,params,callback)
  },
  //应收款汇总列表
  getReceivableList(params,callback){
    return request.post(process.env.BASE_URL+`/project/getProjectReceivablesList`,params,callback)
  },
  //应收款汇总详情
  getReceivableDetail(params,callback){
    return request.post(process.env.BASE_URL+`/project/getProjectReceivablesDetailList`,params,callback)
  }
}
export default receivable