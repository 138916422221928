export default {
    getCurrentPosition(param) {
        if (!("geolocation" in navigator)) {
            fail("当前不支持获取地理位置")
        }
        const {
            success,
            fail,
            enableHighAccuracy,
            maximumAge,
            timeout
        } = param
        let option = {
            enableHighAccuracy: enableHighAccuracy ? enableHighAccuracy : false,
            maximumAge: maximumAge ? maximumAge : 0,
            timeout: timeout ? timeout : 99999
        }
        navigator.geolocation.getCurrentPosition(p => {
            success({
                longitude: p.coords.longitude,
                latitude: p.coords.latitude,
                altitude: p.coords.altitude,
                accuracy: p.coords.accuracy,
                altitudeAccuracy: p.coords.altitudeAccuracy,
                heading: p.coords.heading,
                speed: p.coords.speed,
                timestamp: p.timestamp,
            })
        }, e => {
            fail(e)
        }, option)// 传入经纬度，设置地图中心点
    }
}
