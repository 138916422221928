import push from './modules/push'
import analytics from './modules/analytics'
import windowConfig from './modules/windowConfig'
import navigation from './modules/navigation'
import system from './modules/system'
import weChat from './modules/weChat'
import permission from './modules/permission'

//*********     直接使用android   *********
//*********     直接使用web    *********
import request from '../web/modules/request'
import location from '../web/modules/location'

export default {
    ...navigation,
    push,
    windowConfig,
    analytics,
    request,
    weChat,
	permission,
    ...system,
    ...location
}
