import uniappApi from '../uniappApi'

export default {
    makePhoneCall(param) {
        window.location.href = "tel:" + param.phoneNumber;
    },
    sendMsg(param) {
        window.location.href = "sms:" + param.phoneNumber;
    },
    openBrowser(param) {
        uniappApi.plusCallPre(() => {
            plus.runtime.openURL(param.url, null, null);
        })
    },
    share(param,options) {
        // {
        //     type:'web',
        //         title:'分享标题',
        //     content:'分享内容',
        //     thumbs:'',
        //     href:'http://www.dcloud.io/',
        // }
        // options 分享渠道
        uniappApi.plusCallPre(() => {
            let shareNaitve = function () {
                plus.share.sendWithSystem(param, function () {
                    console.log('分享成功');
                }, function (e) {
                    console.log('分享失败：' + JSON.stringify(e));
                });
            }
            plus.share.getServices(function (s) {
                let shares = s;
                let button = []
                for (let item in shares) {
                    button.push({
                        title: shares[item].description,
                        func: function () {
                            shares[item].send(param)
                        }
                    })
                }
                button.push({
                    title: '系统分享',
                    func: function () {
                        shareNaitve()
                    }
                })
                plus.nativeUI.actionSheet(
                    {
                        title: "分享",
                        cancel: "取消",
                        buttons: button
                    },
                    function (e) {
                        if(e.index === 0){
                            return
                        }
                        button[e.index-1].func()
                        console.log("User pressed: " + e.index);
                    }
                );
            }, function (e) {
                alert("获取分享服务列表失败： " + JSON.stringify(e));
                shareNaitve()
            });

        })
    },
    openFile(param) {
        uniappApi.plusCallPre(() => {
            plus.runtime.openURL(param.url, null, null);
        })
    },
    getWindowInfo(param) {
        uniappApi.plusCallPre(() => {
            if (param.success) {
                param.success({
                    statusBarHeight: window.plus.navigator.getStatusbarHeight()
                })
            }
        })
    },
    openAppAuthorizeSetting() {
        uniappApi.plusCallPre(() => {
            if (plus.os.name == 'Android') {
                let packageName = 'com.zhilan.housekeeping'
                var Uri = plus.android.importClass("android.net.Uri");
                var Settings = plus.android.importClass("android.provider.Settings");
                var context = plus.android.runtimeMainActivity();
                var packageURI = Uri.parse("package:" + packageName);
                var intent = plus.android.newObject("android.content.Intent", Settings.ACTION_APPLICATION_DETAILS_SETTINGS, packageURI);
                context.startActivity(intent);
            } else if (plus.os.name == 'iOS') {
                var UIApplication = plus.ios.import("UIApplication");
                var application2 = UIApplication.sharedApplication();
                var NSURL2 = plus.ios.import("NSURL");
                var setting2 = NSURL2.URLWithString("app-settings:");
                application2.openURL(setting2);
                plus.ios.deleteObject(setting2);
                plus.ios.deleteObject(NSURL2);
                plus.ios.deleteObject(application2);
            }
        })
    }
}
