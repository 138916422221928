import request from '@/api/http'
const mailBox = {
    mailList(params,callback){
        return request.post(process.env.BASE_URL+`/lvJingAPI/comm/mail/maillist`, params,callback)
    },
    unreadList(params,callback){
        return request.post(process.env.BASE_URL+`/lvJingAPI/comm/mail/unread_list`, params,callback)
    },
    readAll(params,callback){
        return request.post(process.env.BASE_URL+`/lvJingAPI/comm/mail/read_all`, params,callback)
    },
    deleteMail(params,callback){
        return request.post(process.env.BASE_URL+`/lvJingAPI/comm/mail/mail_del`, params,callback)
    },
    mailDetail(params,callback){
        return request.post(process.env.BASE_URL+`/lvJingAPI/comm/mail/readmail/`+params.mailId, params,callback)
    }
}

export default mailBox