import uniappApi from '../uniappApi'

export default function (param) {
    // 微信登录鉴权服务
    var weixinOauth = null;
    uniappApi.plusCallPre(() => {
        plus.oauth.getServices((services) => {
            for (var i in services) {
                var service = services[i];
                // 获取微信登录对象
                if (service.id == 'weixin') {
                    weixinOauth = service;
                    break;
                }
            }
            if (weixinOauth == null) {
                param.fail({
                    code: -1,
                    msg: '当前暂不支持微信登录'
                })
                return
            }
            weixinOauth.authorize((oauth) => {
                param.success({
                    code: oauth.code,
                    // unionid: oauth.target.authResult.unionid,
                    // unionid: oauth.target.authResult.openid,
                    // access_token: oauth.target.authResult.access_token,
                    // userInfo: oauth.target.userInfo
                })
                // 授权成功，weixinOauth.authResult 中保存授权信息
            }, function (err) {
                param.fail({
                    code: -1,
                    msg: JSON.stringify(err)
                })
                // 登录授权失败
                // err.code是错误码
            }, {appid: 'wxcfcfa07b475743c6', appsecret: '0e5e6f1dc1f0528152894c361576d70a'})
        }, function (err) {
            param.fail({
                code: -1,
                msg: JSON.stringify(err)
            })
        });
    })
}
