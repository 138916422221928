import nw from '@/nvwa/versionLast/index'
import moduleEmployeeInfo from './modules/moduleEmployeeInfo'
import moduleMenuInfo from './modules/moduleMenuInfo'
import moduleRoleInfo from './modules/moduleRoleInfo'
import moduleUserInfo from './modules/moduleUserInfo'
const { aplus_queue } = window;

export default {
    namespaced: true,
    modules: {
        employeeInfo: moduleEmployeeInfo,
        menuInfo: moduleMenuInfo,
        roleInfo: moduleRoleInfo,
        userInfo: moduleUserInfo
    },
    state: {
        isLogin: false,
        cookie: "",
        isDebug: false,
        homePageRoute: '/temp-home'
    },
    mutations: {
        isDebug(state, isDebug) {
            localStorage.setItem("isDebug", isDebug)
            state.isDebug = isDebug
        },
        init(state) {
            state.isDebug = localStorage.getItem("isDebug") == null ? false : JSON.parse(localStorage.getItem("isDebug"))
            state.cookie = localStorage.getItem("cookie")
            state.isLogin = localStorage.getItem("isLogin") === "true"
            state.homePageRoute = localStorage.getItem("homePageRoute")
        },
        updateCookie(state, cookie) {
            localStorage.setItem("cookie", cookie)
            state.cookie = cookie
        },
        isLogin(state, isLogin) {
            localStorage.setItem("isLogin", isLogin)
            state.isLogin = isLogin
        },
        logout(state) {
            state.isLogin = false
            localStorage.setItem("isLogin", "false")
        },
        updateHomePageRoute(state, isTemp) {
            if (isTemp) {
                state.homePageRoute = '/temp-home'
            } else {
                state.homePageRoute = '/home'
            }
            localStorage.setItem("homePageRoute", state.homePageRoute)
        }
    },
    actions: {
        refreshLoginInfo({ dispatch, commit, state }) {
            return new Promise((resolve, reject) => {
                nw.request({
                    url: process.env.BASE_URL + `/user/refresh`,
                    method: 'get',
                    success(data) {
                        if (data.data.code == 0) {
                            commit('isLogin', "true")
                            dispatch('updateFromTo', data.data)
                            if (localStorage.getItem("unionId") != null && data.data.data.userInfo.unionId != localStorage.getItem("unionId")) {
                                localStorage.removeItem("unionId")
                            }
                            nw.analytics({
                                params: {
                                    type: "login",
                                    provider: "self",//账号来源（如微信）
                                    userId: data.data.data.employeeInfo.employeeId//账号id（不能为空）
                                }
                            }
                            )

                            nw.push.setAlias({
                                params: {
                                    type: 'setAlias',
                                    aliasType: 'employeeId',
                                    alias: data.data.data.employeeInfo.employeeId
                                },
                            })
                        }
                        resolve(data.data)
                    },
                    fail() {
                        reject()
                    }
                }
                )
            })
        },
        login({ dispatch, commit, state }, loginInfo) {
            return new Promise((resolve, reject) => {
                nw.request({
                    url: process.env.BASE_URL + `/user/login`,
                    data: loginInfo,
                    method: 'post',
                    success(data) {
                        if (data.data.code == 0) {
                            localStorage.setItem("password", loginInfo.passWord)
                            commit('isLogin', "true")
                            dispatch('updateFromTo', data.data)
                            if (localStorage.getItem("unionId") != null && data.data.data.userInfo.unionId != localStorage.getItem("unionId")) {
                                localStorage.removeItem("unionId")
                            }
                            nw.analytics({
                                params: {
                                    type: "login",
                                    provider: "self",//账号来源（如微信）
                                    userId: data.data.data.employeeInfo.employeeId//账号id（不能为空）
                                }
                            }
                            )

                            nw.push.setAlias({
                                params: {
                                    type: 'setAlias',
                                    aliasType: 'employeeId',
                                    alias: data.data.data.employeeInfo.employeeId
                                },
                            })
                        }
                        resolve(data.data)
                    },
                    fail() {
                        reject()
                    }
                }
                )
            })

        },
        loginByWx({ dispatch, commit, state }, unionId) {
            return new Promise((resolve, reject) => {
                nw.request({
                    url: process.env.BASE_URL + `/user/wxLogin`,
                    data: unionId,
                    method: 'post',
                    success(data) {
                        if (data.data.code == 0) {
                            commit('isLogin', "true")
                            dispatch('updateFromTo', data.data)
                            localStorage.setItem("unionId", data.data.data.userInfo.unionId)
                            nw.analytics({
                                params: {
                                    type: "login",
                                    provider: "self",//账号来源（如微信）
                                    userId: data.data.data.employeeInfo.employeeId//账号id（不能为空）
                                }
                            }
                            )
                        } else {
                            commit('isLogin', "false")
                        }
                        resolve(data.data)
                    },
                    fail() {
                        reject()
                    }
                }
                )
            })

        },
        logout({ commit, state }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                nw.analytics({
                    params: {
                        type: "logout",
                    }
                }
                )
                nw.push.removeAlias({
                    params: {
                        aliasType: 'employeeId',
                        alias: state.employeeInfo.employeeId
                    },
                })
                resolve()
            })
        },
        init({ dispatch, commit }) {
            let isLogin = localStorage.getItem("isLogin") || false
            if (isLogin) {
                commit('init')
            } else {
                commit('isLogin', false)
            }
        },
        updateFromTo({ commit }, AllStateInfo) {
            // 没有权限，跳转至临时工主页
            commit('updateHomePageRoute', AllStateInfo.data.menuList.length <= 0)
            commit('updateEmployeeInfo', AllStateInfo.data.employeeInfo)
            commit('updateMenuInfo', AllStateInfo.data.menuList)
            commit('updateRoleInfo', AllStateInfo.data.roleInfo)
            commit('updateUserInfo', AllStateInfo.data.userInfo)
            commit('updateCookie', AllStateInfo.APP_LVJING)
            commit('updateDepartment', AllStateInfo.data.department)
            aplus.uploadUserProfile(JSON.stringify({
                ak: '61121387063bed4d8c12357b',
                sdt: 'h5mp',
                id: AllStateInfo.data.userInfo.id,
                it: 'uuid'
            }), function (res) {
                console.log('uploadUserProfile: ', res);
            });
        },
    },
    getters: {
        hasLogin: (state) => () => {
            return state.isLogin
        }
    }
}
