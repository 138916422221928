import uniappApi from '../uniappApi'

export default {
    getCurrentPosition(param) {
        const {
            success,
            fail,
            enableHighAccuracy,
            isHighAccuracy,
            cacheTimeout,
            highAccuracyExpireTime,
            coordsType,
            timeout,
            maximumAge,
            provider,
            geocode} = param
        let option = {
            enableHighAccuracy:enableHighAccuracy?enableHighAccuracy: true,
            isHighAccuracy:isHighAccuracy?isHighAccuracy: true,
            cacheTimeout:cacheTimeout?cacheTimeout: 1,
            highAccuracyExpireTime:highAccuracyExpireTime?highAccuracyExpireTime: 3500,
            timeout:timeout?timeout: 99999,
            maximumAge:maximumAge?maximumAge:0,
            provider:provider,
            coordsType:coordsType,
            geocode: geocode?geocode:true
        }
        uniappApi.plusCallPre(()=> {
            plus.geolocation.getCurrentPosition((p) => {
                console.log(JSON.stringify(p))
                success({
                    longitude: p.coords.longitude,
                    latitude: p.coords.latitude,
                    altitude: p.coords.altitude,
                    accuracy: p.coords.accuracy,
                    altitudeAccuracy: p.coords.altitudeAccuracy,
                    heading: p.coords.heading,
                    speed: p.coords.speed,
                    timestamp: p.timestamp,
                })
            }, (e) => {
                fail(e.message)
            }, option);
        })
    },
}
