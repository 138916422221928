/***    学习中心    ***/
const learnCenter = () => import(/* webpackChunkName: "learnCenter" */ "@/views/learnCenter/LearnCenter")
const learnPictureDetail = () => import(/* webpackChunkName: "learnCenter" */ "@/views/learnCenter/LearnPictureDetail")
const learnVideoDetail = () => import(/* webpackChunkName: "learnCenter" */ "@/views/learnCenter/LearnVideoDetail")
const learnPdfDetail = () => import(/* webpackChunkName: "learnCenter" */ "@/views/learnCenter/LearnPdfDetail")

//
export default [
    {
        path: '/learnCenter',
        name: 'learnCenter',
        meta: {
            index: 3,
            title: "学习中心"
        },
        component: learnCenter,
    },
    {
        path: '/learnPictureDetail/:title',
        name: 'learnPictureDetail',
        meta: {
            index: 4,
            title: "学习中心"
        },
        component: learnPictureDetail,
        props:true
    },
    {
        path: '/learnVideoDetail/:title',
        name: 'learnVideoDetail',
        meta: {
            index: 4,
            title: "学习中心"
        },
        component: learnVideoDetail,
        props:true
    },
    {
        path: '/learnPdfDetail/:title',
        name: 'learnPdfDetail',
        meta: {
            index: 8,
            title: "学习中心"
        },
        component: learnPdfDetail,
        props:true
    },
]