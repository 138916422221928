import Router from '@/router/index'
import nwApi from '../nwApi'

export default {
    navigateTo(param) {
        let reg = /(http|https):\/\/([\w.]+\/?)\S*/;
        param.route=param.url
        if (!reg.test(param.url)) {
            Router.push(param.url)
        }else{
            window.location.href = "browser:"+param.url;
        }
    },
    redirectTo(param) {
        Router.replace(this.parseUrl(param.url))
    },
    navigateBack(param){
        nwApi.common('navigation',{
            params:{
                type:"backPress"
            }
        })
        // Router.go(-1)
    },
    reLaunch(param) {
        Router.replace(param.url)
    },
    parseUrl(url) {
        if (url.indexOf('?') === -1) {
            return url
        } else {
            var result = {};
            var query = url.split("?");
            var queryArr = query[1].split("&");
            queryArr.forEach(function (item) {
                var value = item.split("=")[1];
                var key = item.split("=")[0];
                result[key] = value;
            });
            localStorage.setItem("routerArgus", JSON.stringify(result))
            return query[0]
        }
    },
    routerArgus() {
        return JSON.parse(localStorage.getItem("routerArgus"))
    },
    hideSystemNavigation(){
        nwApi.common('windowConfig',{
            params: {
                hideActionBar: true
            }
        })
    },
    showSystemNavigation(){

    }
}
