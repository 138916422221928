const ContractHandoverList = () => import(/* webpackChunkName: "contractHandover" */ "@/views/contractHandover/contractHandoverList")
const ContractHandoverListDetail = () => import(/* webpackChunkName: "contractHandover" */ "@/views/contractHandover/contractHandoverListDetail")

export default [
    {
        path: '/contractHandover/contractHandoverList',
        name: 'ContractHandoverList',
        meta: {
            index: 3,
            title: "合同移交"
        },
        component: ContractHandoverList,
        props: true
    },
    {
        path: '/contractHandover/contractHandoverListDetail/:type/:id?',
        name: 'ContractHandoverListDetail',
        meta: {
            index: 4,
            title: "合同移交详情"
        },
        component: ContractHandoverListDetail,
        props: true
    },
]