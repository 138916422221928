import request from '@/api/http'
import nw from "@/nvwa/versionLast";

const uniPush = {
    cidBindAlias (params,callback) {
        console.log('cidBindAlias',params)
        return nw.request({
            "method": 'POST',
            "url": process.env.BASE_URL+`/push-api/cidBindAlias`,
            "data": params,
            success(response){
                callback(response)
            },
            fail(err){
                callback('fail',err)
            }
        })
    },
    unboundAlias (params,callback) {
        return nw.request({
            "method": 'POST',
            "url": process.env.BASE_URL+`/push-api/unboundAlias`,
            "data": params,
            success(response){
                callback(response)
            },
            fail(err){
                callback('fail',err)
            }
        })
    }
}

export default uniPush
