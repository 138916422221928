import uniappApi from '../uniappApi'
import permission_js_sdk from "./permission_js_sdk"
import {
	Dialog
} from 'vant'
export default {
	checkPermissionAndAlert(permissionName, successCallback, failCallback) {
		successCallback()
		return
		let permissionType = {
			"android.permission.ACCESS_FINE_LOCATION": {
				title: "定位提示",
				content: "获取您的位置用于打卡"
			},
			"android.permission.READ_EXTERNAL_STORAGE": {
				title: "申请相册权限",
				content: "我们需要获取您的相册权限，以便上传照片。"
			},
		}
		let permissionContent = permissionType[permissionName]
		// androd
	 	uniappApi.plusCallPre(async () => {
			let showDialog = true
			setTimeout(() => {
				if (showDialog) {
					Dialog({
						message: permissionContent.content
					});
				}

			}, 100)

			var result = await permission_js_sdk.requestAndroidPermission(permissionName)
			Dialog.close()
			showDialog = false
			let strStatus
			if (result == 1) {
				strStatus = "已获得授权"
			} else if (result == 0) {
				strStatus = "未获得授权"
			} else {
				strStatus = "被永久拒绝权限"
				Dialog.confirm({
						title: '您已经拒绝了权限',
						message: '是否打开设置开启',
					})
					.then(() => {
						permission_js_sdk.gotoAppPermissionSetting()
						// on confirm
					})
					.catch(() => {
						// on cancel

					});
			}
		})
	}
}