export default {
    state: {
        deductPrice:0,//其他扣款金额
        receivableData: {},//整体应收款数据
        punishMoney: 0,//确认单扣罚金额
        rewardMoney: 0,//确认单奖励金额
        processPrizeAmount: 0,//流程奖励金额
        processFineAmount: 0,//流程扣罚金额
        purchaseOrderItem: {},//流程闭环项整体
        purchaseOrderList: [
            {
                purchaseOrderNumber: "",
                processBeginTime: "",
                processEndTime: "",
            }] //流程闭环列表
    },
    mutations: {
        //更新其他扣款金额
        updateDeductPrice(state, data){
            state.deductPrice = data
        },
        //更新整体应收款数据
        updateReceivableData(state, data) {
            state.receivableData = data
        },
        //更新确认单扣罚金额
        updatePunishMoney(state, data) {
            state.punishMoney = data
        },
        //更新确认单奖励金额
        updateRewardMoney(state, data) {
            state.rewardMoney = data
        },
        //更新流程奖励金额
        updateProcessPrizeAmount(state, data) {
            state.processPrizeAmount = data
        },
        //更新流程扣罚金额
        updateProcessFineAmount(state, data) {
            state.processFineAmount = data
        },
        //跟新流程闭环项整体
        updatePurchaseOrderItem(state, data) {
            state.purchaseOrderItem = data
        },
        //跟新流程闭环项开始时间
        updatePurchaseOrderItemBeginTime(state, data){
            state.purchaseOrderItem.processBeginTime = data
        },
        //跟新流程闭环项结束时间
        updatePurchaseOrderItemEndTime(state, data){
            state.purchaseOrderItem.processEndTime = data
        },
        //更新流程闭环列表
        updatePurchaseOrderList(state, data) {
            state.purchaseOrderList = data
        }
    },
    actions: {
    },
    getters: {
        deductPrice: state => state.deductPrice,
        receivableData: state => state.receivableData,
        punishMoney: state => state.punishMoney,
        rewardMoney: state => state.rewardMoney,
        processPrizeAmount: state => state.processPrizeAmount,
        processFineAmount: state => state.processFineAmount,
        purchaseOrderItem: state => state.purchaseOrderItem,
        purchaseOrderList: state => state.purchaseOrderList
    }
}