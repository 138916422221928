import moduleLoginStatus from './modules/moduleLoginStatus/moduleLoginStatus'
import moduleBindingCollection from './modules/bindingCollection/bindingCollection'
import moduleReceivablesListDetail from './modules/receivablesListDetail/receivablesListDetail'
import windowConfig from './modules/moduleWindowConfig'

export default new Vuex.Store({
    modules:{
        loginStatus:moduleLoginStatus,
        bindingCollection:moduleBindingCollection,
        windowConfig,
        moduleReceivablesListDetail
    }
})
