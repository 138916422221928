import push from './modules/push'
import analytics from './modules/analytics'
import windowConfig from './modules/windowConfig'
import navigation from './modules/navigation'
import permission from './modules/permission'
import system from './modules/system'
import location from './modules/location'
import weChat from './modules/weChat'
import request from '../web/modules/request'

export default {
    ...navigation,
    push,
    windowConfig,
    analytics,
    request,
    permission,
    ...system,
    ...location,
    weChat
}
