<template>
  <div class="action_bar_body">
    <div class="action_bar_sticky" ref="actionBarSticky">
      <div v-if="backgroundImg!==''"
           :style="{height:topStickyHeight+'px'}"
           class="action_bar_back_img">
        <img class="img"
             :src="backgroundImg">
      </div>
      <div class="statusBar" :style="{backgroundColor:backgroundColor,height:statusBarHeight+'px'}"></div>

      <van-nav-bar
          :style="{backgroundColor:backgroundColor}"
          z-index="101"
          ref="navBar"
          @click-left="onClickLeft"
          @click-right="$emit('click-right')"
          :border="border"
      >
        <van-button slot="left" v-if="showBack" color="transparent">
          <van-icon name="arrow-left" size="20px" :color="color"></van-icon>
        </van-button>
        <div slot="left" v-if="customLeft">
          <slot name="left"></slot>
        </div>
        <div slot="title" :style="{color:color}" @click="titleClick()">{{ title }}</div>
        <div slot="right" class="action_bar_default_right">
          <slot name="right"></slot>
        </div>
      </van-nav-bar>
      <slot  name="bottom"></slot>
    </div>
    <div v-if="showExtra" :style="{height:topStickyHeight+'px'}"></div>
  </div>
</template>

<script>

export default {
  name: 'actionBar',
  props: {
    backgroundColor: {
      default: '#00C0A6'
    },
    color: {
      default: 'white'
    },
    title: {
      default: '绿晶服务'
    },
    border: {
      type: Boolean,
      default: true
    },
    backgroundImg: {
      default: ''
    },
    showBack: {
      default: true
    },
    customLeft:{
      default: false
    },
    clickRight: {
      type: Function
    },
    titleClick: {
      type: Function
    },
    showExtra: {//防底部遮挡
      type: Boolean,
      default: true
    }
  },
  created() {
    this.$nw.getWindowInfo({
      success:res=>{
        this.statusBarHeight = res.statusBarHeight
        this.refreshHeight()
      }
    })
  },
  mounted() {
    document.title = this.title
  },
  watch: {
    title(v) {
      document.title = v
    }
  },
  activated(){
    this.refreshHeight()
  },
  data() {
    return {
      topStickyHeight: 0,
      actionBarHeight: 0,
      statusBarHeight: 0,
      changeListenderList:[]
    }
  },
  methods: {
    onClickLeft() {
      if (this.clickRight) {
        this.clickRight()
        return
      }
      if (this.$nw.UA.isIOS) {
        window.IOSBackClick = true
      }
      this.$nw.navigateBack()
    },
    refreshHeight() {
      this.$nextTick(()=>{
        this.topStickyHeight = this.$refs.actionBarSticky.offsetHeight
        this.actionBarHeight = this.$refs.navBar.offsetHeight
        this.$store.commit("updateActionBarHeight",this.topStickyHeight)
        this.changeListenderList.forEach(listenerFunc=>{
          listenerFunc()
        })
      })
    },
    registerChangeListener(func){
      this.changeListenderList.push(func)
    }
  },
}
</script>

<style scoped lang='scss'>
.action_bar_body {
  width: 375px;
}

.action_bar_sticky {
  position: fixed;
  width: 375px;
  z-index: 9999;
}

.action_bar_back_img {
  width: 375px;
  z-index: -1;
  position: absolute;
  overflow: hidden;

  .img {
    width: 375px;
  }
}

.action_bar_default_right {
  color: white;
  font-size: 16px;
}

.statusBar {
  width: 100%;
}

</style>
