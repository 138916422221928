/***    主页    ***/
const ProcessHome = () => import(/* webpackChunkName: "process" */ "@/views/process/ProcessHome")
const NewProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/NewProcess")
const ProcessDeposit = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/ProcessDeposit")
const ProcessDepositOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/ProcessDepositOld")
const MyProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/MyProcess")
const MyCcProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/MyCcProcess")
const MyReview = () => import(/* webpackChunkName: "process" */ "@/views/process/MyReview")
const Error = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/Error")
const ProcessProjectEnter = () => import(/* webpackChunkName: "process-project" */ "@/views/process/modules/project/ProcessProjectEnter")
const ProcessProjectEnterOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/project/ProcessProjectEnterOld")
const ProcessProjectExit = () => import(/* webpackChunkName: "process-project" */ "@/views/process/modules/project/ProcessProjectExit")
const ProcessProjectExitOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/project/ProcessProjectExitOld")
const ProcessProjectTransfer = () => import(/* webpackChunkName: "process-project" */ "@/views/process/modules/project/ProcessProjectTransfer")
const ProcessProjectTransferOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/project/ProcessProjectTransferOld")
const ProcessContractSell = () => import(/* webpackChunkName: "process-contract" */ "@/views/process/modules/contract/ProcessContractSell")
const ProcessContractSellOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/contract/ProcessContractSellOld")
const ProcessSupplementaryContract = () => import(/* webpackChunkName: "process-contract" */ "@/views/process/modules/contract/ProcessSupplementaryContract")
const ProcessSupplementaryContractOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/contract/ProcessSupplementaryContractOld")
const CashReimbursement = () => import(/* webpackChunkName: "process-finance" */ "@/views/process/modules/finance/CashReimbursement")
const CashReimbursementOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/finance/CashReimbursementOld")
const FinanceLoan = () => import(/* webpackChunkName: "process-finance" */ "@/views/process/modules/finance/FinanceLoan")
const FinanceLoanOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/finance/FinanceLoanOld")
const FinancePayment = () => import(/* webpackChunkName: "process-finance" */ "@/views/process/modules/finance/FinancePayment")
const FinancePaymentOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/finance/FinancePaymentOld")
const FinanceTravel = () => import(/* webpackChunkName: "process-finance" */ "@/views/process/modules/finance/FinanceTravel")
const FinanceTravelOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/finance/FinanceTravelOld")
const FinanceSuffered = () => import(/* webpackChunkName: "process-finance" */ "@/views/process/modules/finance/FinanceSuffered")
const FinanceSufferedOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/finance/FinanceSufferedOld")
const CustomProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/other/CustomProcess")
const CustomProcessOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/other/CustomProcessOld")
const SealProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/other/SealProcess")
const SealProcessOld = () => import(/* webpackChunkName: "process-old" */ "@/views/process/modulesOld/other/SealProcessOld")
const SocialSecurityProcess = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/other/SocialSecurityProcess")
const ProcessCardReplacement = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/personnel/ProcessCardReplacement")
const EquipmentScrapping = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/equipment/EquipmentScrapping")
const DeviceTransfer = () => import(/* webpackChunkName: "process" */ "@/views/process/modules/equipment/DeviceTransfer")

export default [
    {
        path: '/process',
        name: 'ProcessHome',
        meta: {
            index: 3,
            title: "流程中心"
        },
        children:[
            {
                path: '/process/my_process',
                name: 'MyProcess',
                meta: {
                    index: 5,
                    title: "我的流程"
                },
                component: MyProcess,
            },
            {
                path: '/process/my_ccProcess',
                name: 'MyCcProcess',
                meta: {
                    index: 5,
                    title: "我的流程"
                },
                component: MyCcProcess,
            },
            {
                path: '/process/my_review',
                name: 'MyReview',
                meta: {
                    index: 4,
                    title: "我的流程"
                },
                component: MyReview,
            },
            {
                path: 'home',
                name: 'NewProcess',
                meta: {
                    index: 4,
                    title: "新申请"
                },
                component: NewProcess,
            },
        ],
        component: ProcessHome,
    },

    {
        path: '/process/deposit/:type/:id?',
        name: 'ProcessDeposit',
        meta: {
            index: 6,
            title: "投标及履约保证金"
        },
        component: ProcessDeposit,
    },
    {
        path: '/process/depositOld/:type/:id?',
        name: 'ProcessDepositOld',
        meta: {
            index: 6,
            title: "投标及履约保证金"
        },
        component: ProcessDepositOld,
    },
    {
        path: '/process/error/:type/:id?',
        name: 'Error',
        meta: {
            index: 6,
            title: "提示页面"
        },
        component: Error,
    },
    {
        path: '/process/project_enter/:type/:id?',
        name: 'ProcessProjectEnter',
        meta: {
            index: 6,
            title: "项目进场"
        },
        component: ProcessProjectEnter,
    },
    {
        path: '/process/project_enterOld/:type/:id?',
        name: 'ProcessProjectEnterOld',
        meta: {
            index: 6,
            title: "项目进场"
        },
        component: ProcessProjectEnterOld,
    },
    {
        path: '/process/project_exit/:type/:id?',
        name: 'ProcessProjectExit',
        meta: {
            index: 6,
            title: "项目退场"
        },
        component: ProcessProjectExit,
    },
    {
        path: '/process/project_exitOld/:type/:id?',
        name: 'ProcessProjectExitOld',
        meta: {
            index: 6,
            title: "项目退场"
        },
        component: ProcessProjectExitOld,
    },
    {
        path: '/process/project_transfer/:type/:id?',
        name: 'ProcessProjectTransfer',
        meta: {
            index: 6,
            title: "项目转区域"
        },
        component: ProcessProjectTransfer,
    },
    {
        path: '/process/project_transferOld/:type/:id?',
        name: 'ProcessProjectTransferOld',
        meta: {
            index: 6,
            title: "项目转区域"
        },
        component: ProcessProjectTransferOld,
    },
    {
        path: '/process/contract_sell/:type/:id?',
        name: 'ProcessContractSell',
        meta: {
            index: 6,
            title: "销售合同评审流程"
        },
        component: ProcessContractSell,
    },
    {
        path: '/process/contract_sellOld/:type/:id?',
        name: 'ProcessContractSellOld',
        meta: {
            index: 6,
            title: "销售合同评审流程"
        },
        component: ProcessContractSellOld,
    },
    {
        path: '/process/supplementary-contract/:type/:id?',
        name: 'ProcessSupplementaryContract',
        meta: {
            index: 6,
            title: "合同补充协议流程"
        },
        component: ProcessSupplementaryContract,
    },
    {
        path: '/process/supplementary-contractOld/:type/:id?',
        name: 'ProcessSupplementaryContractOld',
        meta: {
            index: 6,
            title: "合同补充协议流程"
        },
        component: ProcessSupplementaryContractOld,
    },
    {
        path: '/process/finance_cash/:type/:id?',
        name: 'CashReimbursement',
        meta: {
            index: 6,
            title: "现金报销申请"
        },
        component: CashReimbursement,
    },
    {
        path: '/process/finance_cashOld/:type/:id?',
        name: 'CashReimbursementOld',
        meta: {
            index: 6,
            title: "现金报销申请"
        },
        component: CashReimbursementOld,
    },
    {
        path: '/process/finance_loan/:type/:id?',
        name: 'FinanceLoan',
        meta: {
            index: 6,
            title: "借款申请"
        },
        component: FinanceLoan,
    },
    {
        path: '/process/finance_loanOld/:type/:id?',
        name: 'FinanceLoanOld',
        meta: {
            index: 6,
            title: "借款申请"
        },
        component: FinanceLoanOld,
    },
    {
        path: '/process/finance_payment/:type/:id?',
        name: 'FinancePayment',
        meta: {
            index: 6,
            title: "费用支付申请"
        },
        component: FinancePayment,
    },
    {
        path: '/process/finance_paymentOld/:type/:id?',
        name: 'FinancePaymentOld',
        meta: {
            index: 6,
            title: "费用支付申请"
        },
        component: FinancePaymentOld,
    },
    {
        path: '/process/finance_travel/:type/:id?',
        name: 'FinanceTravel',
        meta: {
            index: 6,
            title: "差旅费报销申请"
        },
        component: FinanceTravel,
    },
    {
        path: '/process/finance_travelOld/:type/:id?',
        name: 'FinanceTravelOld',
        meta: {
            index: 6,
            title: "差旅费报销申请"
        },
        component: FinanceTravelOld,
    },
    {
        path: '/process/finance_suffered/:type/:id?',
        name: 'FinanceSuffered',
        meta: {
            index: 6,
            title: "工伤赔付申请"
        },
        component: FinanceSuffered,
    },
    {
        path: '/process/finance_sufferedOld/:type/:id?',
        name: 'FinanceSufferedOld',
        meta: {
            index: 6,
            title: "工伤赔付申请"
        },
        component: FinanceSufferedOld,
    },
    {
        path: '/process/seal/:type/:id?',
        name: 'SealProcess',
        meta: {
            index: 6,
            title: "用印申请流程"
        },
        component: SealProcess,
    },
    {
        path: '/process/sealOld/:type/:id?',
        name: 'SealProcessOld',
        meta: {
            index: 6,
            title: "用印申请流程"
        },
        component: SealProcessOld,
    },
    {
        path: '/process/custom/:type/:id?',
        name: 'CustomProcess',
        meta: {
            index: 6,
            title: "文件处理流程"
        },
        component: CustomProcess,
    },
    {
        path: '/process/customOld/:type/:id?',
        name: 'CustomProcessOld',
        meta: {
            index: 6,
            title: "文件处理流程"
        },
        component: CustomProcessOld,
    },
    {
        path: '/process/socialSecurity/:type/:id?',
        name: 'SocialSecurityProcess',
        meta: {
            index: 6,
            title: "社保薪资相关流程"
        },
        component: SocialSecurityProcess,
    },
    {
        path: '/process/processCardReplacement/:type/:id?',
        name: 'ProcessCardReplacement',
        meta: {
            index: 6,
            title: "补卡流程"
        },
        component: ProcessCardReplacement,
    },
    {
        path: '/process/equipmentScrapping/:type/:id?',
        name: 'EquipmentScrapping',
        meta: {
            index: 6,
            title: "设备报废流程"
        },
        component: EquipmentScrapping,
    },
    {
        path: '/process/deviceTransfer/:type/:id?',
        name: 'DeviceTransfer',
        meta: {
            index: 6,
            title: "设备转移流程"
        },
        component: DeviceTransfer,
    },
]
