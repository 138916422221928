import nw from '@/nvwa/versionLast'

export default {
    state:{
        notify:{
            show:false,
            message:'',
            marginTop:0,
            type:'warning'
        },
        statusBarHeight:0,
        actionBarHeight:0
    },
    mutations:{
        init(state) {
            nw.getWindowInfo({
                success:res=>{
                    state.statusBarHeight = res.statusBarHeight
                }
            })
        },
        updateActionBarHeight(state,height) {
            state.actionBarHeight = height
        },
        showNotify(state,options){
            let {duration,message,NotActionBar,type}=options
            state.notify.show=true
            state.notify.message=message
            state.notify.type=type?type:'warning'
            if(NotActionBar){
                state.notify.marginTop=state.statusBarHeight
            }else{
                state.notify.marginTop=state.actionBarHeight
            }
            if(state.notify.marginTop<=0){
                state.notify.marginTop=state.statusBarHeight
            }
            setTimeout(()=>{
                state.notify.show=false
            },duration?duration:1500)
        }
    }
}
