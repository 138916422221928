/***    物资申领    ***/

const suppliesHome = () => import(/* webpackChunkName: "supplies" */ "@/views/supplies/SuppliesHome")
/***   申请单（审核及查看复用）    ***/
const SuppliesForm = () => import(/* webpackChunkName: "supplies" */ "@/views/supplies/reuse/SuppliesForm")
const SuppliesFormDetail = () => import(/* webpackChunkName: "supplies" */ "@/views/supplies/reuse/SuppliesFormDetail")

/***   审核    ***/
const SuppliesApprovalList = () => import(/* webpackChunkName: "supplies" */ "@/views/supplies/approval/SuppliesApprovalList")
/***   申请    ***/
const SuppliesApplicationList = () => import(/* webpackChunkName: "supplies-apply" */ "@/views/supplies/application/SuppliesApplicationList")
const SuppliesApplicationCentralized = () => import(/* webpackChunkName: "supplies-apply" */ "@/views/supplies/application/SuppliesApplicationCentralized")
const SuppliesApplicationSporadic = () => import(/* webpackChunkName: "supplies-apply" */ "@/views/supplies/application/SuppliesApplicationSporadic")
const SuppliesSelectProject = () => import(/* webpackChunkName: "supplies-apply" */ "@/views/supplies/application/SuppliesSelectProject")
const SuppliesApplicationSelectMaterial = () => import(/* webpackChunkName: "supplies-apply" */ "@/views/supplies/application/SuppliesApplicationSelectMaterial")
const SuppliesApplicationSporadicSelectMaterial = () => import(/* webpackChunkName: "supplies-apply" */ "@/views/supplies/application/SuppliesApplicationSporadicSelectMaterial")
/***   入库    ***/
const SuppliesInStorageList = () => import(/* webpackChunkName: "supplies-apply" */ "@/views/supplies/inStorage/SuppliesInStorageList")
const SuppliesInStorageForm = () => import(/* webpackChunkName: "supplies-apply" */ "@/views/supplies/inStorage/SuppliesInStorageForm")

// const scatteredPurchase = () => import(/* webpackChunkName: "supplies" */ "@/views/supplies/scatteredPurchase")

export default [
    {
        path: '/supplies',
        name: 'SuppliesHome',
        meta: {
            index: 3,
            title: "物资采购",
            useApp: true,
            needLogin:true
        },
        component: suppliesHome
    },
    {
        path: '/supplies/form/:type',
        name: 'SuppliesForm',
        meta: {
            index: 9,
            title: "申请单"
        },
        component: SuppliesForm
    },
    {
        path: '/supplies/formDetail',
        name: 'SuppliesFormDetail',
        meta: {
            index: 10,
            title: "单个物资详情"
        },
        component: SuppliesFormDetail
    },
    {
        path: '/supplies/approval',
        name: 'SuppliesApprovalList',
        meta: {
            index: 4,
            title: "我审批的"
        },
        component: SuppliesApprovalList
    },
    {
        path: '/supplies/application',
        name: 'SuppliesApplicationList',
        meta: {
            index: 4,
            title: "我提交的"
        },
        component: SuppliesApplicationList
    },
    {
        path: '/supplies/application/centralized',
        name: 'SuppliesApplicationCentralized',
        meta: {
            index: 5,
            subIndex:1,
            title: "集中采购"
        },
        component: SuppliesApplicationCentralized
    },
    {
        path: '/supplies/application/sporadic',
        name: 'SuppliesApplicationSporadic',
        meta: {
            index: 5,
            subIndex:1,
            title: "零星采购"
        },
        component: SuppliesApplicationSporadic
    },
    {
        path: '/supplies/selectProject',
        name: 'SuppliesSelectProject',
        meta: {
            index: 5,
            subIndex:2,
            title: "项目点选择"
        },
        component: SuppliesSelectProject
    },
    {
        path: '/supplies/application/selectMaterial',
        name: 'SuppliesApplicationSelectMaterial',
        meta: {
            index: 10,
            title: "添加申请物资"
        },
        component: SuppliesApplicationSelectMaterial
    },
    {
        path: '/supplies/application/selectSporadicMaterial',
        name: 'SuppliesApplicationSporadicSelectMaterial',
        meta: {
            index: 10,
            title: "添加申请物资"
        },
        component: SuppliesApplicationSporadicSelectMaterial
    },
    {
        path: '/supplies/inStorageList',
        name: 'SuppliesInStorageList',
        meta: {
            index: 4,
            title: "入库"
        },
        component: SuppliesInStorageList
    },
    {
        path: '/supplies/inStorageForm',
        name: 'SuppliesInStorageForm',
        meta: {
            index: 5,
            title: "待入库申请单"
        },
        component: SuppliesInStorageForm
    },
]