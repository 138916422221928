export default {
    state: {
        bindList:[],
    },
    mutations: {
        initBindList(state,data){
            state.bindList = []
        },
        updateBindList(state,data){
            state.bindList = data.choosedList
        }
    },
    actions: {
    },
    getters:{
        bindList: state => state.bindList,
    }
}