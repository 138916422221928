import { Toast, Dialog} from 'vant';
import main from '@/main'
import router from '@/router'
import store from '@/store'
import nw from '@/nvwa/versionLast'
const {aplus_queue} = window;

const tip = msg => {
    main.$dialog({message:msg});
}
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (result, callback,requestParams) => {
    // 状态码判断
    switch (result.status) {
        case 200:
            apiHandle(result.data, callback,requestParams)
            break;
        case undefined:
            if(result == 'Error: Request failed with status code 504'){
                tip('请检查网络链接')
                callback('fail',{code:'network_error'})
            }else if(result =='Error: timeout of 10000ms exceeded'){
                tip('请求超时')
                callback('fail',{code:'network_error'})
            }
            callback('fail',{code:'other_error'})
            break
        default :
            callback('fail',{code:'gateway_error',gateway_code:result.status})
            aplus_queue.push({
                action: 'aplus.record',
                arguments: ['REQUEST_ERROR', 'ERROR', {
                    'method': requestParams.method || 'POST',
                    'url': requestParams.url,
                    'data': JSON.stringify(requestParams.data),
                    'headers': JSON.stringify(requestParams.header),
                    'res':JSON.stringify(result)
                }]
            });
            break;
    }
}

const apiHandle = (data, callback,requestParams) => {
    switch (data.code) {
        case '0':
        case 0:
            callback(data.data,data)
            break
        case '-2':
        case -2:
            Toast.clear()
            localStorage.clear()
            let msg = '您的登录态已过期，请重新登录'
            if (!store.getters['loginStatus/hasLogin']()) {
                msg = '请先登录'
            }else{
                store.dispatch('loginStatus/logout').then(
                    () => {
                    }
                )
            }
            Dialog.alert({
                title: '提示',
                message: msg
            }).then(() => {
                store.dispatch('loginStatus/logout').then(
                    () => {
                        router.push('/afterLogin')
                    }
                )
            });
            callback('fail')
            //TODO 登陆失败，跳转到登陆页
            break
        default:
            Toast.clear()
            tip(data.msg || '请重试')
            callback('fail',data)
            aplus_queue.push({
                action: 'aplus.record',
                arguments: ['API_ERROR', 'ERROR', {
                    'method': requestParams.method || 'POST',
                    'url': requestParams.url,
                    'data': JSON.stringify(requestParams.data),
                    'headers': JSON.stringify(requestParams.header),
                    'res':JSON.stringify(data)
                }]
            });
    }
}
const request = {
    post: function (url, params, callback) {
        let requestParams= {
            'method': params.method || 'POST',
            'url': url,
            'data': params,
            'timeout': params.timeout || null,
            'header': {
                Cookie: localStorage.getItem('cookie')
            }
        }
        nw.request({...requestParams,
            success(response) {
                errorHandle(response, callback,requestParams)
            },
            fail(res){
                errorHandle(res, callback,requestParams)
            }
        })
    },
    get:function (url, params, callback) {
        let paramsKey=Object.keys(params)
        if(paramsKey.length>0){
            url+='?'
            paramsKey.forEach(item=>{
                url+=item+'='+encodeURIComponent(params[item])+'&'
            })
            url=url.slice(0,-1)
        }
        let requestParams= {
            'method':'GET',
            'url': url,
            'header': {
                Cookie: localStorage.getItem('cookie')
            },
            'timeout': params.timeout || null,
        }
        nw.request({...requestParams,
            success(response) {
                errorHandle(response, callback,requestParams)
            },
            fail(res){
                errorHandle(res, callback,requestParams)
            }
        })
    },
};


export default request;
