const AttendanceList = () => import(/* webpackChunkName: "attendance" */ "@/views/attendance")
const AttendanceDetail = () => import(/* webpackChunkName: "attendance" */ "@/views/attendance/detail")
export default[
    {
        path:'/attendanceList',
        name:'AttendanceList',
        meta: {
            index: 3,
            title: "考勤"
        },
        component:AttendanceList
    },
    {
        path:'/attendanceList/detail/:weekRange/:projectId',
        name:'AttendanceDetail',
        meta: {
            index: 4,
            title: "考勤详情"
        },
        component:AttendanceDetail
    },
]