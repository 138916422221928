import nwApi from "@/nvwa/versionLast/ios/nwApi";

export default {
    setAlias(param){
        param.params.type='setAlias'
        nwApi.common('push',param)
    },
    removeAlias(param){
        param.params.type='removeAlias'
        nwApi.common('push',param)
    },
    getAllMessage(callback) {
            callback([])
    }
}