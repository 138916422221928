import request from '@/api/http'

const examination = {
    getExamList(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/exam/exam/paging`, params,callback)
    },
    getExamDetail(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/exam/exam/detail`, params,callback)
    },
    createPaper(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/paper/paper/create-paper`, params,callback)
    },
    getPaperDetail(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/paper/paper/paper-detail`, params,callback)
    },
    getQuDetail(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/paper/paper/qu-detail`, params,callback)
    },
    fillAnswer(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/paper/paper/fill-answer`, params,callback)
    },
    handExam(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/paper/paper/hand-exam`, params,callback)
    },
    paperResult(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/paper/paper/paper-result`, params,callback)
    },
    recordsList(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/paper/paper/paging`, params,callback)
    },
    nextQu(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/user/wrong-book/next`, params,callback)
    },
    fetchQuDetail(params,callback){
        return request.post(process.env.BASE_URL+`/exam/api/qu/qu/detail`, params,callback)
    },
}
export default examination